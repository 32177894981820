import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Crida l’atenció pel seu color taronja viu. Té forma de copa al principi passant a ser pla i més o manco ondulat, fins a 1,5 cm de diàmetre i amb l’himeni vermellós a la part interna. La superficie externa del mateix color està coberta de pèls curts i marronencs. Les espores són el·líptiques, amb els extrems més prims i ornades amb una xarxa gruixuda a manera de reticle molt vistosa, que als extrems presenta espines curtes, de 16-19 x 8-10 micres sense comptar el reticle que pot tenir fins a 4 micres d’altura.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      